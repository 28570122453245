
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { ElMessageBox, ElMessage } from "element-plus";
import usersApi from "@/core/services/UsersApi";
import membersApi from "@/core/services/MembersApi";
import { hideModal } from "@/core/helpers/dom";
import { Field, Form } from "vee-validate";
import { object, string } from "yup";

export default defineComponent({
    name: "users",
    components: {
        Form,
        Field,
    },
    data() {
        return {
            users: [] as any,
            nBailliages: [] as any,
            types: [
                {
                    id: 0,
                    label: "Member",
                },
                {
                    id: 1,
                    label: "Extern",
                },
            ],
            permissions: [] as any,
            th: [
                {
                    label: "ID",
                    class: "min-w-80px",
                    colName: "id",
                },
                {
                    label: "Name",
                    class: "min-w-130px",
                    colName: "lastname",
                },
                {
                    label: "Firstname",
                    class: "min-w-130px",
                    colName: "firstname",
                },
                {
                    label: "Member",
                    class: "min-w-150px",
                    colName: "is_member",
                },
                {
                    label: "Grade",
                    class: "min-w-130px",
                    colName: "grade",
                },
                {
                    label: "Bailliage",
                    class: "min-w-150px",
                    colName: "bailliage",
                },
                {
                    label: "Profil",
                    class: "min-w-140px",
                    colName: "profil",
                },
            ],
            actualFilter: "",
            userSearchQuery: "",
            editingUser: false,
            actualCol: "",
            currentPage: 1,
            pageClass: "page-item",
            linkClass: "page-link",
            selectedUsers: [] as any,
            loading: true,
            userType: "Member",
            usersCount: 0,
            totalPages: 0,
            remoteMembers: [] as any,
            newUserMember: "",
            newUserBailliage: "",
            newUserBailliageSelect: null as any,
            newUserCode: "",
            userId: "",
            newUser: {
                email: "",
                permission: "",
                userprofile: {
                    firstname: "",
                    lastname: "",
                },
            } as any,
            perm_pos: "" as any,
            lockBailliage: false,
        };
    },
    setup() {
        // Number of items per page
        const resultsPerPage = 10;
        const addUserModalRef = ref<null | HTMLElement>(null) as any;

        onMounted(() => {
            setCurrentPageBreadcrumbs("Users", []);
        });

        function createDebounce() {
            let timeout: any;
            return function (fnc, delayMs) {
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                    fnc();
                }, delayMs || 500);
            };
        }

        const memberUserValidationSchema = object().shape({
            // userType: string().required().label("User type").nullable(),
            permissions: string()
                .required()
                .label("User permissions")
                .nullable(),
            member: string().required().label("Member name or ID").nullable(),
            email: string().required().label("User email").nullable(),
            bailliage: string().required().label("User bailliage").nullable(),
        });

        const externUserValidationSchema = object().shape({
            // userType: string().required().label("User type").nullable(),
            permissions: string()
                .required()
                .label("User permissions")
                .nullable(),
            bailliage: string().required().label("User bailliage").nullable(),
            firstname: string().required().label("User firstname").nullable(),
            lastname: string().required().label("User lastname").nullable(),
            email: string().required().label("User email").nullable(),
        });

        const closeModal = () => {
            hideModal(addUserModalRef.value);
        };

        return {
            resultsPerPage,
            addUserModalRef,
            closeModal,
            memberUserValidationSchema,
            externUserValidationSchema,
            debounce: createDebounce(),
        };
    },
    mounted() {
        this.getUsers();
        this.getFields();
        this.perm_pos = localStorage.getItem("perm_pos") as any;
    },
    methods: {
        handleSizeChange() {
            this.getUsers();
        },
        editUser(user: any) {
            console.log(user);

            this.userId = user.id;
            user.is_member
                ? (this.userType = "Member")
                : (this.userType = "Extern");
            this.editingUser = true;
            this.newUserMember =
                user.userprofile.firstname + " " + user.userprofile.lastname;
            this.userType === "Member"
                ? (this.newUserBailliage = user.userprofile.national_bailliage
                      ? user.userprofile.national_bailliage.label
                      : "")
                : user.userprofile.national_bailliage
                ? user.userprofile.national_bailliage.id
                : "";

            this.newUser = {
                email: user.email,
                permission: user.permission.id,
                userprofile: {
                    firstname: user.userprofile.firstname,
                    lastname: user.userprofile.lastname,
                    national_bailliage: user.userprofile.national_bailliage
                        ? user.userprofile.national_bailliage.label
                        : "",
                },
            };
            this.newUserBailliage = user.userprofile.national_bailliage
                ? user.userprofile.national_bailliage.id
                : "";
        },
        clearForm() {
            // if (!this.editingUser) {
            //     this.remoteMembers = [];
            //     this.newUser.permission = "";
            //     this.newUserMember = "";
            //     this.newUserBailliage = "";
            //     this.newUserBailliageSelect = null;
            //     this.newUser.email = "";
            //     if (this.newUser.userprofile) {
            //         this.newUser.userprofile.firstname = "";
            //         this.newUser.userprofile.lastname = "";
            //     }
            if (this.perm_pos !== "0") {
                this.newUserBailliageSelect = parseInt(
                    localStorage.getItem("bailliage") as any
                );
                this.lockBailliage = true;
            }
            // }

            // setTimeout(() => {
            //     (document.querySelector("#resetForm") as any).click();
            // }, 150);
        },
        loadRemoteMembers(query) {
            return new Promise((resolve, reject) => {
                if (query !== "" && query.length >= 2) {
                    this.remoteMembers = [];
                    membersApi
                        .listRemote({ query: query, status: "active" })
                        .then((response) => {
                            if (response.success) {
                                response.data.map((remoteMember) => {
                                    this.remoteMembers.push({
                                        id: remoteMember.id,
                                        code: remoteMember.code,
                                        firstname:
                                            remoteMember.userprofile.firstname,
                                        lastname:
                                            remoteMember.userprofile.lastname.toUpperCase(),
                                        bailliage: remoteMember.userprofile
                                            .national_bailliage
                                            ? remoteMember.userprofile
                                                  .national_bailliage.label
                                            : "",
                                        email:
                                            remoteMember.contact &&
                                            remoteMember.contact.email !== "" &&
                                            remoteMember.contact.email
                                                ? remoteMember.contact.email
                                                : remoteMember.contact_pro &&
                                                  remoteMember.contact_pro
                                                      .email !== "" &&
                                                  remoteMember.contact_pro.email
                                                ? remoteMember.contact_pro.email
                                                : "",
                                    });
                                });
                                resolve("");
                            } else {
                                console.log("ERROR");
                            }
                        });
                } else {
                    this.remoteMembers = [];
                    resolve("");
                }
            });
        },
        querySearchAsync(queryString, cb) {
            this.loadRemoteMembers(queryString).then(() => {
                cb(this.remoteMembers);
            });
        },
        handleSelect(item: any) {
            this.newUserMember = item.firstname + " " + item.lastname;
            this.newUser.email = item.email;
            this.newUserBailliage = item.bailliage;

            console.log(this.userType);

            if (this.userType === "Member") {
                this.newUser.member = item.id;
            } else {
                delete this.newUser.member;
                this.newUser.userprofile.firstname = item.firstname;
                this.newUser.userprofile.lastname = item.lastname;
            }

            this.remoteMembers = [];
        },
        addUser() {
            var topCheckbox = document.getElementById("topCheckbox") as any;

            this.loading = true;

            if (topCheckbox) topCheckbox.checked = false;
            if (this.userType === "Member") {
                delete this.newUser.userprofile;
                this.newUser.code = this.newUserCode;
            }

            if (this.userType === "Extern" && this.editingUser)
                this.newUser.userprofile.national_bailliage =
                    this.newUserBailliage;
            if (this.userType === "Extern" && !this.editingUser)
                this.newUser.userprofile.national_bailliage =
                    this.newUserBailliageSelect;

            if (this.editingUser) {
                usersApi
                    .editUser(this.userId, this.newUser)
                    .then((res: any) => {
                        if (res.success) {
                            ElMessage({
                                type: "success",
                                message: "user updated!",
                            });
                            this.getUsers();
                            this.closeModal();
                            this.editingUser = false;
                            this.loading = false;
                        }
                    });
            } else {
                usersApi.addUser(this.newUser).then((res: any) => {
                    this.loading = false;

                    if (res.data.success === true) {
                        ElMessage({
                            type: "info",
                            message:
                                "An email has been sent to the user to confirm his account.",
                            duration: 5000,
                        });
                        this.getUsers();
                        this.closeModal();
                        this.clearForm();
                    } else {
                        if (res.data.status == 409) {
                            ElMessage({
                                type: "error",
                                message:
                                    "This email is already used by another user.",
                                duration: 3000,
                            });
                        } else {
                            this.getUsers();
                            this.closeModal();
                            ElMessage({
                                type: "error",
                                message: "An error occurred.",
                                duration: 5000,
                            });
                        }
                    }
                });
            }
        },
        handlePagination(pageNumber: any) {
            this.selectedUsers = [];
            var topCheckbox = document.getElementById("topCheckbox") as any;
            topCheckbox.checked = false;

            this.currentPage = pageNumber;
            this.getUsers();
        },
        getFields() {
            usersApi.getPermissions().then((response) => {
                this.permissions = response.data.permissions;
            });

            membersApi.getBailliages().then((res) => {
                var bailliages = res.data;
                bailliages.map((bailliage: any) => {
                    if (bailliage.type === "National")
                        this.nBailliages.push(bailliage);
                });
            });
        },
        getUsers() {
            var payload = {
                p: this.currentPage,
                presult: 10,
            } as any;

            this.loading = true;
            this.selectedUsers = [];
            this.users = [];
            this.newUser = {
                email: "",
                permission: "",
                userprofile: {
                    firstname: "",
                    lastname: "",
                },
            };

            if (this.actualFilter && this.actualCol) {
                // Si tri et pas de filtres
                payload = {
                    p: this.currentPage,
                    presult: this.resultsPerPage,
                    column: this.actualCol,
                    order: this.actualFilter,
                };
            } else {
                // Sinon on affiche tout
                payload = {
                    p: this.currentPage,
                    presult: this.resultsPerPage,
                    status: "active",
                };
            }

            if (this.userSearchQuery) {
                payload = { ...payload, query: this.userSearchQuery };
            }

            usersApi.getUsers(payload).then((res) => {
                res.data.user_list.map((user: any) => {
                    this.users.push(user);
                });
                this.usersCount = res.data.user_count;
                this.totalPages = res.data.page_count;
                this.loading = false;
            });
        },
        deleteUsers(userId?: number) {
            var usersIdToDelete = [] as any;
            var topCheckbox = document.getElementById("topCheckbox") as any;

            if (this.selectedUsers.length !== 0)
                usersIdToDelete = this.selectedUsers.map((user) => user.id);
            else usersIdToDelete = [userId];

            this.loading = true;
            this.selectedUsers = [];
            if (topCheckbox) topCheckbox.checked = false;
            usersApi.deleteUsers(usersIdToDelete).then(() => {
                this.loading = false;
                ElMessage({
                    type: "success",
                    message: "Successfully deleted users!",
                });
                this.getUsers();
            });
        },
        // Open Multi-Delete confirmation modal
        confirmDeleteSelected() {
            if (this.selectedUsers.length < 2) {
                ElMessage({
                    type: "error",
                    message: "Please select two or more users.",
                });
            } else {
                ElMessageBox.confirm(
                    "You have requested the deletion of multiple users.<br>Would you like to confirm this choice?",
                    "Confirmation",
                    {
                        confirmButtonText: "Confirm",
                        cancelButtonText: "Cancel",
                        customClass: "custom-modal",
                        cancelButtonClass: "cancel-modal",
                        dangerouslyUseHTMLString: true,
                    }
                )
                    .then(() => {
                        this.deleteUsers();
                    })
                    .catch(() => {
                        return; // Cancel
                    });
            }
        },
        // Open Delete confirmation modal
        confirmDelete(userId: number) {
            ElMessageBox.confirm(
                "You have requested the deletion of this user.<br>Would you like to confirm this choice?",
                "Confirmation",
                {
                    confirmButtonText: "Confirm",
                    cancelButtonText: "Cancel",
                    customClass: "custom-modal",
                    cancelButtonClass: "cancel-modal",
                    dangerouslyUseHTMLString: true,
                }
            )
                .then(() => {
                    this.deleteUsers(userId);
                })
                .catch(() => {
                    return; // Cancel
                });
        },
        sortColumn(column: string, id: number) {
            var arrows = document.getElementById("chevrons" + id);

            // Hide all arrows
            Array.from(
                document.getElementsByClassName("chevrons-container") as any
            ).map((filter: any) => {
                filter.style.display = "none";
            });

            // Display the right arrows
            if (arrows) arrows.style.display = "flex";

            // If we click for the first time on a column OR if we change the column
            if (this.actualCol === "" || this.actualCol !== column) {
                this.actualCol = column;
                this.actualFilter = "asc";
                this.handleChevron("show", "up");
                this.handleChevron("hide", "down");

                // Else if we click on the same column
            } else if (this.actualCol === column) {
                if (this.actualFilter === "asc") {
                    this.handleChevron("hide", "up");
                    this.handleChevron("show", "down");
                    this.actualFilter = "desc";
                } else if (this.actualFilter === "desc") {
                    this.handleChevron("show", "up");
                    this.handleChevron("hide", "down");
                    this.actualFilter = "asc";
                }
            }
            this.getUsers();
        },
        handleChevron(action: string, direction: string) {
            var chevrons: any;

            if (direction === "up")
                chevrons = Array.from(
                    document.getElementsByClassName("fa-chevron-up") as any
                );
            else if (direction === "down")
                chevrons = Array.from(
                    document.getElementsByClassName("fa-chevron-down") as any
                );

            if (action === "show")
                chevrons.map((chevron: any) => {
                    chevron.classList.add("active-chevron");
                });
            else if (action === "hide")
                chevrons.map((chevron: any) => {
                    chevron.classList.remove("active-chevron");
                });
        },
        checkAll() {
            var topCheckbox = document.getElementById("topCheckbox") as any;
            var checkboxes = Array.from(
                document.getElementsByClassName("custom-checkbox") as any
            );

            this.selectedUsers = [];

            if (topCheckbox.checked) {
                checkboxes.map((checkbox: any) => {
                    checkbox.checked = true;
                });
                this.users.map((user: any) => {
                    this.selectedUsers.push(user);
                });
            } else {
                checkboxes.map((checkbox: any) => {
                    checkbox.checked = false;
                });
                this.selectedUsers = [];
            }
        },
        handleUserSelect(person: any) {
            var focusedCheckbox = document.getElementById(
                "checkbox" + person.id
            ) as any;

            if (focusedCheckbox?.checked) {
                this.selectedUsers.push(person);
            } else {
                this.selectedUsers.map((user: any) => {
                    if (user.id === person.id) {
                        this.selectedUsers.splice(
                            this.selectedUsers.indexOf(user),
                            1
                        );
                    }
                });
            }
        },
        handleQuery(query) {
            this.userSearchQuery = query;
            this.getUsers();
        },
    },
});
